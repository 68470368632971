import { FunctionComponent, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '~/providers/AuthProvider';

const PrivateRoute: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const user = useAuth();
  const location = useLocation();

  return user != null ? (
    <>{children}</>
  ) : (
    <Navigate state={{ from: location }} to={{ pathname: '/login' }} />
  );
};

export default PrivateRoute;
