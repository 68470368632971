/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ComponentType, useState, useEffect, useContext, FunctionComponent } from 'react';

import { SkillsContext, Skill } from '~/providers/SkillsProvider';
import { Job } from '~/providers/JobsProvider';

import './About.scss';

interface Lang {
  languageName: string;
  languageLevel: string;
}

interface StateType {
  jobs: Array<Job>;
  languages: Array<Lang>;
  ExperienceCard: ComponentType<Job> | null;
  Language: ComponentType<Lang> | null;
  SkillBadge: ComponentType<Skill> | null;
}

const jobs = [
  {
    jobTitle: 'Web Programmer',
    company: 'NEAT integrated design',
    location: 'Cali, Colombia',
    from: '12/2012',
    to: '10/2014',
    description: '',
  },
  {
    jobTitle: 'Frontend Developer',
    company: 'Softpymes SA',
    location: 'Cali, Colombia',
    from: '10/2014',
    to: '07/2016',
    description: '',
  },
  {
    jobTitle: 'Teacher',
    company: 'UAO',
    location: 'Cali, Colombia',
    from: '02/2016',
    to: '05/2016',
    description: '',
  },
  {
    jobTitle: 'Freelance Developer',
    company: 'Jero.io',
    location: 'Nizhny Novgorod, Russia',
    from: '07/2016',
    to: '10/2017',
    description: '',
  },
  {
    jobTitle: 'Senior web UI Developer',
    company: 'Globant',
    location: 'Medellín, Colombia',
    from: '10/2017',
    to: '03/2018',
    description: '',
  },
  {
    jobTitle: 'Senior Frontend Developer',
    company: 'Storiqa',
    location: 'Moscow, Russia',
    from: '03/2018',
    to: '02/2019',
    description: '',
  },
  {
    jobTitle: 'Senior Frontend Developer',
    company: 'Alabom',
    location: 'Moscow, Russia',
    from: '02/2019',
    to: '04/2019',
    description: '',
  },
  {
    jobTitle: 'Senior Frontend Developer',
    company: 'Team International',
    location: 'Medellín, Colombia',
    from: '06/2019',
    to: '09/2019',
    description: '',
  },
  {
    jobTitle: 'Senior Frontend Developer',
    company: 'Sberbank',
    location: 'Moscow, Russia',
    from: '09/2019',
    to: '06/2022',
    description: '',
  },

  {
    jobTitle: 'Senior Frontend Developer',
    company: 'Glovo',
    location: 'Madrid, Spain',
    from: '06/2022',
    to: 'to date',
    description: '',
  },
].reverse();

const initialState: StateType = {
  jobs,
  languages: [
    {
      languageName: 'Español',
      languageLevel: 'Native',
    },
    {
      languageName: 'English',
      languageLevel: 'Proeficient',
    },
    {
      languageName: 'Русский',
      languageLevel: 'Intermediate',
    },
  ],
  ExperienceCard: null,
  Language: null,
  SkillBadge: null,
};

const About: FunctionComponent = () => {
  const [state, setState] = useState(initialState);
  const skillList = useContext(SkillsContext);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const {
          target: { id },
        } = entry;

        if (entry.intersectionRatio > 0 && state[id] == null) {
          import(`../${id}/`).then((module) => {
            setState((prevState) => ({
              ...prevState,
              [id]: module[id],
            }));
          });
        }
      });
    });

    const components = document.querySelectorAll('#ExperienceCard, #Language, #SkillBadge');

    if (components != null) {
      components.forEach((component) => {
        observer.observe(component);
      });
    }
  }, [state]);

  const { languages, ExperienceCard, Language, SkillBadge } = state;

  return (
    <section className="about" id="about">
      <div className="container">
        <h2 className="text-center">About</h2>
        <hr />
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <p className="text-center heading-subtitle">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              I'm frontend developer, passionate about web technologies and minimal UI's
            </p>
          </div>
        </div>
        <h3 className="text-center mt-3 mb-3">Experience</h3>
        <div
          className="d-flex flex-wrap align-items-start justify-content-around mb-3"
          id="ExperienceCard"
        >
          {ExperienceCard && jobs.map((job, idx) => <ExperienceCard key={idx} {...job} />)}
        </div>
        <hr />
        <h3 className="text-center mt-3 mb-3">Languages</h3>
        <div
          className="d-flex flex-wrap align-items-start justify-content-around mb-3"
          id="Language"
        >
          {Language && languages.map((lang, idx) => <Language key={idx} {...lang} />)}
        </div>
        <hr />
        <h3 className="text-center mt-3 mb-3">Skills</h3>
        <div
          className="d-flex flex-wrap align-items-start justify-content-around mb-3"
          id="SkillBadge"
        >
          {/* @ts-ignore */}
          {SkillBadge && skillList.map((skill, idx) => <SkillBadge key={idx} skill={skill.name} />)}
        </div>
      </div>
    </section>
  );
};

export default About;
