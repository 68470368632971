import { FunctionComponent } from 'react';

import './Contact.scss';

const Contact: FunctionComponent = () => (
  <section className="contact" id="contact">
    <article className="container">
      <h2 className="text-center">Email Me</h2>
      <hr />
      <h4 className="text-center heading-subtitle">info@jero.dev</h4>
      {/* <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <form name="contactForm" id="contactForm" noValidate>
            <div className="control-group">
              <div className="form-group floating-label-form-group controls">
                <label className="sr-only">Name</label>
                <input
                  className="form-control contact__input"
                  id="name"
                  type="text"
                  placeholder="Name"
                />
                <p className="help-block text-danger" />
              </div>
            </div>
            <div className="control-group">
              <div className="form-group floating-label-form-group controls">
                <label className="sr-only">Email</label>
                <input
                  className="form-control contact__input"
                  id="name"
                  type="email"
                  placeholder="Email"
                  required
                />
                <p className="help-block text-danger" />
              </div>
            </div>
            <div className="control-group">
              <div className="form-group floating-label-form-group controls">
                <label className="sr-only">Message</label>
                <textarea
                  className="form-control contact__input"
                  id="message"
                  rows="5"
                  placeholder="Message"
                  required
                >
                </textarea>
                <p className="help-block text-danger" />
              </div>
            </div>
            <br />
            <div id="success" />
            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-lg pull-right">Submit</button>
            </div>
          </form>
        </div>
      </div> */}
    </article>
  </section>
);

export default Contact;
