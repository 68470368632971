import { initializeApp } from 'firebase/app';
/**
 * @link https://stackoverflow.com/a/69048162/5378393
 */
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const config = {
  apiKey: 'AIzaSyBhYT8BQidXHp7sgWmrG30bQsB55sn36M4',
  authDomain: 'jero-dev.firebaseapp.com',
  databaseURL: 'https://jero-dev.firebaseio.com',
  projectId: 'jero-dev',
  storageBucket: 'jero-dev.appspot.com',
  messagingSenderId: '166457487955',
  appId: '1:166457487955:web:bf28ca04155b34e48f4ca6',
  measurementId: 'G-GM95E6G2FB',
};

export const app = initializeApp(config);

export const db = getFirestore();

export const auth = getAuth();
