import { FunctionComponent, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Home } from '~/pages/Home';
import { Layout } from '~/core/Layout';
import PrivateRoute from './PrivateRoute';

const Playground = lazy(() => import('~/pages/Playground/Playground'));
const Admin = lazy(() => import('~/pages/Admin/Dashboard/Dashboard'));
const Login = lazy(() => import('~/pages/Login/Login'));

const Routing: FunctionComponent = () => {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />} path="/">
          <Route index element={<Home />} />
          <Route element={<Playground />} path="/playground" />
          <Route
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Login />
              </Suspense>
            }
            path="/login"
          />
          <Route
            element={
              <PrivateRoute>
                <Suspense fallback={<div>Loading...</div>}>
                  <Admin />
                </Suspense>
              </PrivateRoute>
            }
            path="/admin"
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default Routing;
