import { FunctionComponent } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import reactLogo from '~/assets/img/react-logo.png';
import bootstrapLogo from '~/assets/img/bootstrap-logo.png';
import webpackLogo from '~/assets/img/webpack-logo.png';
import firebaseLogo from '~/assets/img/firebase-logo.png';

import './Footer.scss';

const Footer: FunctionComponent = () => (
  <footer className="text-center">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="footer-col col-md-4">
            <h3>Location</h3>
            <p>
              <i aria-hidden="true" className="fa fa-map-marker" /> Москва, Россия
            </p>
          </div>
          <div className="footer-col col-md-4">
            <h3>Follow me</h3>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a
                  className="btn-social btn-outline"
                  href="https://twitter.com/aneurysmjs"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon className="fa fa-fw" icon={faTwitter} />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="btn-social btn-outline"
                  href="https://www.linkedin.com/in/jeronimo-velasquez-606493bb/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon className="fa fa-fw" icon={faLinkedinIn} />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="btn-social btn-outline"
                  href="https://github.com/aneurysmjs"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon className="fa fa-fw" icon={faGithub} />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-col col-md-4">
            <h3>Made with love</h3>
            <div className="d-flex align-items-center justify-content-around">
              <figure className="footer__thumbnails">
                <img alt="react" className="img-fluid" src={reactLogo} />
              </figure>
              <figure className="footer__thumbnails">
                <img alt="bootstrap" className="img-fluid" src={bootstrapLogo} />
              </figure>
              <figure className="footer__thumbnails">
                <img alt="webpack" className="img-fluid" src={webpackLogo} />
              </figure>
              <figure className="footer__thumbnails">
                <img alt="firebase" className="img-fluid" src={firebaseLogo} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">Copyright &copy; jero.dev {new Date().getFullYear()}</div>
        </div>
      </div>
    </div>
    {/* <div className="scroll-top hidden-lg-up">
      <a className="btn btn-primary page-scroll" href="#page-top">
        <i className="fa fa-chevron-up"></i>
      </a>
    </div> */}
  </footer>
);

export default Footer;
