import { FunctionComponent } from 'react';

import { ProfileHeader } from '~/components/ProfileHeader';

import './Profile.scss';

const Profile: FunctionComponent = () => (
  <section id="profile">
    <div className="container">
      <ProfileHeader />
    </div>
  </section>
);

export default Profile;
