/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, FunctionComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';

import classNames from 'classnames';

import { useAuth } from '~/providers/AuthProvider';
import { auth } from '~/firebase';

import './Navbar.scss';

const Navbar: FunctionComponent = () => {
  const [isOpen, setOpen] = useState(false);
  const user = useAuth();

  const signOut = async (): Promise<void> => {
    try {
      await auth.signOut();
      setOpen(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('sign out error: ', error);
    }
  };

  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-light" id="mainNav">
      <div className="container">
        <Link className="navbar-brand" to="/">
          Jero.dev
        </Link>
        <button
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-toggle="collapse"
          type="button"
          onClick={(): void => {
            setOpen(!isOpen);
          }}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div
          className={classNames('collapse navbar-collapse justify-content-end', {
            show: isOpen,
          })}
        >
          <ul className="navbar-nav">
            {/* <li className="nav-item active">
              <Link className="nav-link cursor-pointer" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link cursor-pointer" to="/playground">
                Playground
              </Link>
            </li> */}
            {user && (
              <Fragment>
                <li className="nav-item">
                  <Link className="nav-link cursor-pointer" to="/admin">
                    Admin
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link cursor-pointer" onClick={(): Promise<void> => signOut()}>
                    Sign out
                  </a>
                </li>
              </Fragment>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
