import { useState, useEffect, createContext, FunctionComponent, PropsWithChildren } from 'react';
import { collection, orderBy as orderByQuery, query, onSnapshot } from 'firebase/firestore';

import { db } from '~/firebase';
import { onNext } from '~/utils';

export interface Skill {
  id: string;
  name: string;
}

export const SkillsContext = createContext<Array<Skill>>([]);

interface PropTypes {
  orderBy?: string;
  path: string;
}

const SkillsProvider: FunctionComponent<PropsWithChildren<PropTypes>> = ({
  path,
  children,
  orderBy = 'createdAt',
}) => {
  const [skills, setSkills] = useState<Skill[]>([]);

  // when component mounts, we're ready to listen changes.
  // when unmounting, it calls "unsubscribe" function returned by `onSnapshot` so we stop listening for those changes.
  // all of this in one line!
  useEffect(() => {
    const skillsRef = collection(db, path);

    const q = query(skillsRef, orderByQuery(orderBy));

    return onSnapshot(q, onNext(setSkills));
  }, [path, orderBy]);

  return <SkillsContext.Provider value={skills}>{children}</SkillsContext.Provider>;
};

export default SkillsProvider;
