import { FunctionComponent } from 'react';

import { Profile } from '~/components/Profile';
import { About } from '~/components/About';
import { Contact } from '~/components/Contact';

import SkillsProvider from '~/providers/SkillsProvider';

import './Home.scss';

const Home: FunctionComponent = () => (
  <section className="home">
    <Profile />

    <SkillsProvider path="skills">
      <About />
    </SkillsProvider>

    <Contact />
  </section>
);

export default Home;
