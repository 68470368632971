import { FunctionComponent } from 'react';

import { Routing } from '~/core/Routing';
import AuthProvider from '~/providers/AuthProvider';

const App: FunctionComponent = () => (
  <AuthProvider>
    <Routing />
  </AuthProvider>
);

export default App;
