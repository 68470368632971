import { StrictMode, FunctionComponent } from 'react';
import { createRoot } from 'react-dom/client';
/**
 * @link https://www.npmjs.com/package/intersection-observer
 * Require the polyfill before requiring any other modules.
 */
import 'intersection-observer';

// import main CSS styles
import './assets/scss/styles.scss';

import { App } from '~/components/App';

const AppWrapper: FunctionComponent = () => (
  <StrictMode>
    <App />
  </StrictMode>
);

const app = document.querySelector('#app');

if (app !== null) {
  const root = createRoot(app); // createRoot(container!) if you use TypeScript
  root.render(<AppWrapper />);
}
