import {
  useState,
  useEffect,
  useContext,
  createContext,
  FunctionComponent,
  /**
   * @see https://blog.logrocket.com/using-react-children-prop-with-typescript/
   */
  PropsWithChildren,
} from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';

import { auth } from '~/firebase';

export const AuthContext = createContext<AuthUser | null>(null);

export type AuthUser = User | null;

export const useAuth = (): AuthUser => useContext(AuthContext);

const AuthProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);

  useEffect(
    () =>
      onAuthStateChanged(auth, (userAuth: User | null) => {
        if (userAuth) {
          setAuthUser(userAuth);
        } else {
          setAuthUser(null);
        }
      }),
    [authUser],
  );

  return <AuthContext.Provider value={authUser}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
