import { FunctionComponent } from 'react';

import jeroImg from '~/assets/img/jero.jpg';

import './ProfileHeader.scss';

const ProfileHeader: FunctionComponent = () => (
  <header className="header-info">
    <figure>
      <img alt="jero" className="img-fluid rounded-circle" src={jeroImg} />
    </figure>
    <div className="intro-text">
      <span className="name">Jerónimo Velásquez</span>
      <span className="skills heading-subtitle">Senior Frontend Developer</span>
    </div>
  </header>
);

export default ProfileHeader;
