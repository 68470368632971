import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import { Navbar } from '~/core/Navbar';
import { Footer } from '~/core/Footer';

import './Layout.scss';

const Layout: FunctionComponent = () => (
  <main className="d-flex flex-column vh-100">
    <Navbar />
    <div className="flex-grow-1 container-fluid">{<Outlet />}</div>
    <Footer />
  </main>
);

export default Layout;
